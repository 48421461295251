import * as amplitude from '@amplitude/analytics-browser'

export default defineNuxtPlugin((_nuxtApp) => {
    const config = useRuntimeConfig()
    if (!import.meta.dev) {
        amplitude.init(config.public.amplitudeKey, {
            defaultTracking: {
                pageViews: true,
                sessions: true,
            },
        })
    }

    const user = useSupabaseUser()
    watch(
        user,
        (newVal) => {
            if (newVal) {
                amplitude.setUserId(newVal.id)
            } else {
                amplitude.setUserId(undefined)
            }
        },
        { immediate: true }
    )

    return {
        provide: {
            trackEvent: ({
                eventName,
                eventProperties = {},
            }: {
                eventName: string
                eventProperties?: Record<string, any>
            }) => {
                console.log(`tracking event [${eventName}]: `, eventProperties)
                amplitude.track(eventName, eventProperties)
            },
        },
    }
})
