import revive_payload_client_4sVQNw7RlN from "/vercel/path0/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/vercel/path0/client/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/vercel/path0/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_fmZWau4t0U from "/vercel/path0/client/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_client_j7wLaRg9eH from "/vercel/path0/client/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytics_client_mtqVw3YRXk from "/vercel/path0/client/plugins/analytics.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/client/plugins/sentry.client.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/client/plugins/vue-query.ts";
import vueuse_gesture_ESG2d8ycm9 from "/vercel/path0/client/plugins/vueuse-gesture.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  motion_fmZWau4t0U,
  plugin_client_j7wLaRg9eH,
  chunk_reload_client_UciE0i6zes,
  analytics_client_mtqVw3YRXk,
  sentry_client_shVUlIjFLk,
  vue_query_wrmMkNpEpe,
  vueuse_gesture_ESG2d8ycm9
]