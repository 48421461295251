<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="glyph">
            <path
                id="Vector"
                d="M8.813 11.2362L12.9105 7.13873L11.8304 6.05859L5.8889 12.0001L11.8304 17.9416L12.9105 16.8615L8.813 12.764H18.1111V11.2362H8.813Z"
                fill="currentColor"
            />
        </g>
    </svg>
</template>

<script lang="ts" setup></script>
