import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter()
    const {
        public: { sentry, apiHost },
    } = useRuntimeConfig()

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        enabled: !import.meta.dev,
        environment: sentry.environment,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
            Sentry.breadcrumbsIntegration({
                console: true,
                dom: true,
                fetch: true,
                history: true,
                xhr: true,
            }),
        ],

        // Configure this whole part as you need it!

        tracesSampleRate: 0.2, // Change in prod

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [apiHost],

        replaysSessionSampleRate: 1.0, // Change in prod
        replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    })

    /**
     * Set the sentry user
     */
    const user = useSupabaseUser()
    watch(
        user,
        (newVal) => {
            if (newVal) {
                Sentry.setUser({
                    id: newVal.id,
                    email: newVal.email || newVal.phone,
                })
            } else {
                Sentry.setUser(null)
            }
        },
        { immediate: true }
    )
})
