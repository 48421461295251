export const payBookingErrors = {
    general:
        'There has been an error while trying to process the payment, Please Retry again in a few seconds',
    invalidCard:
        'There has been an error while trying to process your payment info, please review that all the data is correct and try again',
}

export const createBookingError =
    'There has been an error while trying to create the booking and continue to payment, Please Retry again in a few seconds'

export enum Step {
    Info,
    Payment,
}

export enum ErrorTypes {
    CreateBookingError,
    PayBookingError,
    PayBookingGeneralError,
}

export const errorMessages = {
    [ErrorTypes.CreateBookingError]: createBookingError,
    [ErrorTypes.PayBookingError]: payBookingErrors.invalidCard,
    [ErrorTypes.PayBookingGeneralError]: payBookingErrors.general,
}

export const cartButtonText = {
    [Step.Info]: 'Continue to Payment',
    [Step.Payment]: 'Pay Now',
}
