<template>
    <div>
        <Head>
            <Link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
            />
            <Link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
            />
            <Link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
            />
            <Link rel="manifest" href="/site.webmanifest" />
            <Link
                rel="mask-icon"
                href="/safari-pinned-tab.svg"
                color="#5bbad5"
            />
            <Meta name="msapplication-TileColor" content="#9dd0ff" />
            <Meta name="theme-color" content="#ffffff" />
        </Head>
        <NuxtLoadingIndicator color="#152E54" />
        <NuxtLayout>
            <NuxtPage />
            <ConfirmationDialog />

            <Toaster position="bottom-center" />
            <VueQueryDevtools v-if="dev" />
        </NuxtLayout>
    </div>
</template>

<script setup lang="ts">
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import { Toaster } from 'vue-sonner'

const dev = import.meta.dev

const config = useRuntimeConfig()

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk
            ? `${titleChunk} | ${config.public.appName}`
            : `${config.public.appName}`
    },
})
</script>
