export const Urls = {
    Home: () => '/',
    SignIn: ({ redirectAfter }: { redirectAfter?: string } = {}) =>
        `/sign-in${redirectAfter ? `?${SearchParams.RedirectAfter}=${encodeURIComponent(redirectAfter)}` : ''}`,
    SignUp: () => '/sign-up',
    Auth: ({ redirectAfter }: { redirectAfter?: string } = {}) =>
        `/auth${redirectAfter ? `?${SearchParams.RedirectAfter}=${encodeURIComponent(redirectAfter)}` : ''}`,
    ResetPassword: () => '/reset-password',
    ChangePassword: () => '/change-password',
    Trips: () => `/trips`,
    Deal: ({ id }: { id: string }) => `/deals/${id}`,
    TripDeals: ({ tripId }: { tripId: string }) => `/trips/${tripId}/deals`,
    Contact: () => '/contact',
    DealRedirect: ({ id }: { id: string }) => `/deals/${id}/payment-redirect`,
    BookingStatus: ({ id }: { id: string }) => `/bookings/${id}/status`,
    Trip: ({ id }: { id: string }) => `/trips/${id}`,
    NewTrip: () => `/trips/new`,
}

export const SearchParams = {
    RedirectAfter: 'redirectAfter',
}
