<template>
    <button
        :class="[
            `group relative h-button  overflow-hidden rounded-lg border border-gray-2 px-10 font-600  outline-offset-[3px] transition-transform`,
            props.variant === 'primary'
                ? 'bg-dark-blue text-white outline-tint  hover:bg-dark-blue/90  active:bg-dark-blue/95'
                : '',
            props.variant === 'secondary'
                ? ' bg-white text-black outline-black hover:bg-gray-1 active:bg-gray-1/90'
                : '',
            props.variant === 'destructive'
                ? 'bg-error/85 text-white outline-error/85 hover:bg-error/75 active:bg-error/80'
                : '',

            buttonDisabled
                ? `cursor-default !bg-gray-3 text-black/15 `
                : `cursor-pointer active:scale-99`,
        ]"
        :disabled="buttonDisabled"
        v-bind="slimmedAttrs"
    >
        <div
            :class="[
                'relative flex size-full flex-row items-center justify-center gap-3 transition-opacity',
                props.loading ? 'opacity-0' : 'opacity-100',
            ]"
        >
            <slot />
            <component :is="icon" class="size-5" />
        </div>
        <div
            :class="[
                'absolute inset-0 flex size-full items-center justify-center',
                props.loading ? 'opacity-100' : 'opacity-0',
            ]"
        >
            <Spinner :class="[buttonDisabled ? `text-black/50` : ``]" />
        </div>
    </button>
</template>

<script setup lang="ts">
import { type ButtonHTMLAttributes, type Component } from 'vue'

import Spinner from '~/components/Spinner.vue'

interface ButtonProps extends /** @vue-ignore */ ButtonHTMLAttributes {
    icon?: Component
    loading?: boolean
    variant?: 'primary' | 'secondary' | 'destructive'
}

const props = withDefaults(defineProps<ButtonProps>(), {
    loading: false,
    variant: 'primary',
})

defineOptions({
    inheritAttrs: false,
})

const attrs = useAttrs()

const disabledViaAttr = computed(() => {
    if (attrs['disabled'] !== undefined) {
        return !!attrs['disabled']
    } else {
        return false
    }
})

const slimmedAttrs = Object.fromEntries(
    Object.entries(attrs).filter(([key]) => key !== 'disabled')
)

const buttonDisabled = props.loading || disabledViaAttr
</script>
