export const backButtonVisible = ref<Boolean>(false)
export const currentBackButtonAction = ref<() => void>()

export function useBackButtonInHeader(backButtonAction: Ref<() => void>) {
    watch(
        backButtonAction,
        (action) => {
            currentBackButtonAction.value = action
        },
        { immediate: true }
    )
    return {
        setBackButtonVisible: (visible: boolean) => {
            backButtonVisible.value = visible
        },
    }
}
