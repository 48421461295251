<template>
    <TransitionRoot :show="visible" as="template">
        <Dialog
            class="relative z-dialog"
            @close="
                () => {
                    visible = false
                }
            "
        >
            <TransitionChild
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
                class="fixed inset-0 hidden bg-black/30 backdrop-blur-sm sm:block"
            />

            <div
                class="fixed inset-0 flex w-screen items-center justify-center"
            >
                <TransitionChild
                    enter="duration-300 ease-out"
                    enter-from="opacity-0 translate-y-2 "
                    enter-to="opacity-100 translate-y-0 "
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 "
                    leave-to="opacity-0 translate-y-2 "
                    as="template"
                >
                    <DialogPanel
                        :class="[
                            `flex size-full flex-col items-center gap-8 border border-gray-2 bg-white`,
                            `p-4 py-10 shadow-1 sm:h-auto sm:max-w-dialogContent sm:rounded-2xl sm:p-12`,
                            panelClass,
                        ]"
                    >
                        <DialogTitle
                            v-if="!!title"
                            class="text-center font-500 text-3xl"
                            >{{ title }}</DialogTitle
                        >

                        <slot
                            :trigger-close="
                                () => {
                                    visible = false
                                }
                            "
                        />
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'

const visible = defineModel<boolean>({ default: false })

defineProps<{
    title?: string
    panelClass?: string
}>()
</script>
