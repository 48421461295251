<template>
    <Modal
        v-model="isModalVisible"
        :title="content?.title || ''"
        class="relative z-dialog"
        @update:model-value="
            () => {
                if (!$event) {
                    handleCancel()
                }
            }
        "
    >
        <div class="flex w-full max-w-[260px] flex-col gap-4">
            <Button
                :variant="content?.destructive ? `destructive` : `primary`"
                @click="handleConfirm"
                >{{ content?.confirmText }}</Button
            >

            <Button variant="secondary" @click="handleCancel"> Cancel </Button>
        </div>
    </Modal>
</template>

<script setup lang="ts">
import Button from '~/components/Button.vue'
import Modal from '~/components/Modal.vue'

const { isModalVisible, content } = useConfirmationDialog()

const handleCancel = () => {
    window.dispatchEvent(ConfirmationDialogCancelEvent)
}

const handleConfirm = () => {
    window.dispatchEvent(ConfirmationDialogConfirmEvent)
}
</script>
