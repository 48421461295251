<template>
    <div
        :class="[
            `fixed inset-x-0 top-0 z-header flex h-header w-full items-center justify-center py-3 `,
            route.path === '/'
                ? `text-white`
                : `border-y border-gray-2 bg-white text-black`,
        ]"
    >
        <div class="flex w-full max-w-page items-center px-page-gutter">
            <NuxtLink
                v-show="!backButtonVisible"
                key="link"
                :href="Urls.Home()"
                class="flex items-center gap-2 font-400 text-xl"
            >
                <IconLogo
                    class="w-20"
                    :type="route.path === '/' ? 'white' : 'colored'"
                />
            </NuxtLink>
            <button
                v-show="backButtonVisible"
                key="backButton"
                class="flex flex-row items-center gap-1.5 pr-2 text-tint"
                @click="currentBackButtonAction"
            >
                <IconArrowLeft class="-ml-1.5 mt-px size-6" />
                <div class="font-500">Back</div>
            </button>

            <div class="flex-1" />

            <!-- Mobile Menu -->
            <!-- <div class="sm:hidden">
                <button class="p-1" @click="toggleMobileMenu">
                    <IconHamburger
                        v-if="!mobileMenuOpen"
                        :class="[
                            `size-6`,
                            route.path === '/' ? 'text-white' : `text-tint`,
                        ]"
                    />
                    <IconX v-else class="size-6" />
                </button>
            </div> -->

            <!-- Desktop Menu -->
            <div class="hidden sm:block">
                <!-- <NuxtLink
                    v-if="isAuthenticated"
                    :href="Urls.Trips()"
                    class="mr-7 rounded-lg"
                    >My Trips
                </NuxtLink>
                <NuxtLink
                    v-if="isAuthenticated"
                    :href="Urls.Contact()"
                    class="mr-7 rounded-lg"
                    >Contact Us
                </NuxtLink> -->
                <!-- <button
                    v-if="isAuthenticated"
                    class="cursor-pointer"
                    @click="signOut"
                >
                    Sign Out
                </button> -->
                <!-- <div
                    v-if="!isAuthenticated && route.path !== '/auth'"
                    class="flex items-center gap-7"
                >
                    <NuxtLink :href="Urls.Auth({})">Log In</NuxtLink>
                    <NuxtLink :href="Urls.Auth({})" tabindex="-1">
                        <button
                            class="flex flex-row items-center gap-2 rounded-full border border-white/10 bg-white/10 px-4 py-1.5 font-400 text-white/80 backdrop-blur-md"
                        >
                            <div>Sign Up</div>
                        </button>
                    </NuxtLink>
                </div> -->
            </div>
        </div>
        <!-- <MobileMenuModal v-model="mobileMenuOpen" /> -->
    </div>
</template>

<script setup lang="ts">
import IconLogo from '~/components/Icon/IconLogo.vue'
// import { useAuthentication } from '~/composables/useAuthentication'
import {
    backButtonVisible,
    currentBackButtonAction,
} from '~/composables/useBackButtonInHeader'
import { Urls } from '~/utils/urls'

const route = useRoute()

// const { isAuthenticated, signOut } = useAuthentication()

// const mobileMenuOpen = ref(false)

// function toggleMobileMenu() {
//     mobileMenuOpen.value = !mobileMenuOpen.value
// }
</script>
