import { default as index3h8PdlaKsVMeta } from "/vercel/path0/client/pages/auth/index.vue?macro=true";
import { default as indexktpkJw9txUMeta } from "/vercel/path0/client/pages/bookings/[id]/status/index.vue?macro=true";
import { default as change_45password2N3JuYMj38Meta } from "/vercel/path0/client/pages/change-password.vue?macro=true";
import { default as contactC1l1EYBWITMeta } from "/vercel/path0/client/pages/contact.vue?macro=true";
import { default as constsIvsbl5O7tLMeta } from "/vercel/path0/client/pages/deals/[id]/consts.ts?macro=true";
import { default as indexlW8GwLiBoCMeta } from "/vercel/path0/client/pages/deals/[id]/index.vue?macro=true";
import { default as payment_45redirectw8O0VEoOxBMeta } from "/vercel/path0/client/pages/deals/[id]/payment-redirect.vue?macro=true";
import { default as indexPOLutzK6r6Meta } from "/vercel/path0/client/pages/index.vue?macro=true";
import { default as reset_45passwordA1TFCqetDRMeta } from "/vercel/path0/client/pages/reset-password.vue?macro=true";
import { default as sign_45inmaZoG1Isl4Meta } from "/vercel/path0/client/pages/sign-in.vue?macro=true";
import { default as sign_45uppQsl2OsXUcMeta } from "/vercel/path0/client/pages/sign-up.vue?macro=true";
import { default as indexKDII8JDf51Meta } from "/vercel/path0/client/pages/trips/[id]/deals/index.vue?macro=true";
import { default as indexOnOEkVSK3KMeta } from "/vercel/path0/client/pages/trips/index.vue?macro=true";
import { default as indexug5Dr8JvGuMeta } from "/vercel/path0/client/pages/trips/new/index.vue?macro=true";
export default [
  {
    name: index3h8PdlaKsVMeta?.name ?? "auth",
    path: index3h8PdlaKsVMeta?.path ?? "/auth",
    meta: index3h8PdlaKsVMeta || {},
    alias: index3h8PdlaKsVMeta?.alias || [],
    redirect: index3h8PdlaKsVMeta?.redirect,
    component: () => import("/vercel/path0/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexktpkJw9txUMeta?.name ?? "bookings-id-status",
    path: indexktpkJw9txUMeta?.path ?? "/bookings/:id()/status",
    meta: indexktpkJw9txUMeta || {},
    alias: indexktpkJw9txUMeta?.alias || [],
    redirect: indexktpkJw9txUMeta?.redirect,
    component: () => import("/vercel/path0/client/pages/bookings/[id]/status/index.vue").then(m => m.default || m)
  },
  {
    name: change_45password2N3JuYMj38Meta?.name ?? "change-password",
    path: change_45password2N3JuYMj38Meta?.path ?? "/change-password",
    meta: change_45password2N3JuYMj38Meta || {},
    alias: change_45password2N3JuYMj38Meta?.alias || [],
    redirect: change_45password2N3JuYMj38Meta?.redirect,
    component: () => import("/vercel/path0/client/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: contactC1l1EYBWITMeta?.name ?? "contact",
    path: contactC1l1EYBWITMeta?.path ?? "/contact",
    meta: contactC1l1EYBWITMeta || {},
    alias: contactC1l1EYBWITMeta?.alias || [],
    redirect: contactC1l1EYBWITMeta?.redirect,
    component: () => import("/vercel/path0/client/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: constsIvsbl5O7tLMeta?.name ?? "deals-id-consts",
    path: constsIvsbl5O7tLMeta?.path ?? "/deals/:id()/consts",
    meta: constsIvsbl5O7tLMeta || {},
    alias: constsIvsbl5O7tLMeta?.alias || [],
    redirect: constsIvsbl5O7tLMeta?.redirect,
    component: () => import("/vercel/path0/client/pages/deals/[id]/consts.ts").then(m => m.default || m)
  },
  {
    name: indexlW8GwLiBoCMeta?.name ?? "deals-id",
    path: indexlW8GwLiBoCMeta?.path ?? "/deals/:id()",
    meta: indexlW8GwLiBoCMeta || {},
    alias: indexlW8GwLiBoCMeta?.alias || [],
    redirect: indexlW8GwLiBoCMeta?.redirect,
    component: () => import("/vercel/path0/client/pages/deals/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45redirectw8O0VEoOxBMeta?.name ?? "deals-id-payment-redirect",
    path: payment_45redirectw8O0VEoOxBMeta?.path ?? "/deals/:id()/payment-redirect",
    meta: payment_45redirectw8O0VEoOxBMeta || {},
    alias: payment_45redirectw8O0VEoOxBMeta?.alias || [],
    redirect: payment_45redirectw8O0VEoOxBMeta?.redirect,
    component: () => import("/vercel/path0/client/pages/deals/[id]/payment-redirect.vue").then(m => m.default || m)
  },
  {
    name: indexPOLutzK6r6Meta?.name ?? "index",
    path: indexPOLutzK6r6Meta?.path ?? "/",
    meta: indexPOLutzK6r6Meta || {},
    alias: indexPOLutzK6r6Meta?.alias || [],
    redirect: indexPOLutzK6r6Meta?.redirect,
    component: () => import("/vercel/path0/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordA1TFCqetDRMeta?.name ?? "reset-password",
    path: reset_45passwordA1TFCqetDRMeta?.path ?? "/reset-password",
    meta: reset_45passwordA1TFCqetDRMeta || {},
    alias: reset_45passwordA1TFCqetDRMeta?.alias || [],
    redirect: reset_45passwordA1TFCqetDRMeta?.redirect,
    component: () => import("/vercel/path0/client/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: sign_45inmaZoG1Isl4Meta?.name ?? "sign-in",
    path: sign_45inmaZoG1Isl4Meta?.path ?? "/sign-in",
    meta: sign_45inmaZoG1Isl4Meta || {},
    alias: sign_45inmaZoG1Isl4Meta?.alias || [],
    redirect: sign_45inmaZoG1Isl4Meta?.redirect,
    component: () => import("/vercel/path0/client/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45uppQsl2OsXUcMeta?.name ?? "sign-up",
    path: sign_45uppQsl2OsXUcMeta?.path ?? "/sign-up",
    meta: sign_45uppQsl2OsXUcMeta || {},
    alias: sign_45uppQsl2OsXUcMeta?.alias || [],
    redirect: sign_45uppQsl2OsXUcMeta?.redirect,
    component: () => import("/vercel/path0/client/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: indexKDII8JDf51Meta?.name ?? "trips-id-deals",
    path: indexKDII8JDf51Meta?.path ?? "/trips/:id()/deals",
    meta: indexKDII8JDf51Meta || {},
    alias: indexKDII8JDf51Meta?.alias || [],
    redirect: indexKDII8JDf51Meta?.redirect,
    component: () => import("/vercel/path0/client/pages/trips/[id]/deals/index.vue").then(m => m.default || m)
  },
  {
    name: indexOnOEkVSK3KMeta?.name ?? "trips",
    path: indexOnOEkVSK3KMeta?.path ?? "/trips",
    meta: indexOnOEkVSK3KMeta || {},
    alias: indexOnOEkVSK3KMeta?.alias || [],
    redirect: indexOnOEkVSK3KMeta?.redirect,
    component: () => import("/vercel/path0/client/pages/trips/index.vue").then(m => m.default || m)
  },
  {
    name: indexug5Dr8JvGuMeta?.name ?? "trips-new",
    path: indexug5Dr8JvGuMeta?.path ?? "/trips/new",
    meta: indexug5Dr8JvGuMeta || {},
    alias: indexug5Dr8JvGuMeta?.alias || [],
    redirect: indexug5Dr8JvGuMeta?.redirect,
    component: () => import("/vercel/path0/client/pages/trips/new/index.vue").then(m => m.default || m)
  }
]